:root {
  --on-color: #000;
  --off-color: #ffffff;
  --board-dimension: 100px;
  --cell-dimension: 50px;
  --cell-margin: 1px;
}

body {
  background-color: var(--off-color);
  color: var(--on-color);
  margin: 0;
  padding: 0;
  
}

.color-1 {
  background-color: var(--on-color);
  color: var(--off-color);
}

.color-0 {
  background-color: var(--off-color);
  color: var(--on-color);
}

.cell {
  width: var(--cell-dimension);
  height: var(--cell-dimension);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  margin: var(--cell-margin);
}

.column_2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Game, .container, .root {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: var(--board-dimension);
  min-width: var(--board-dimension);
  height: var(--board-dimension);
  width: var(--board-dimension);
  max-height: var(--board-dimension);
  max-width: var(--board-dimension);
}

.row {
  position: relative;
  float:left;
}

.svg-container {
  position: absolute;
  /*top: 0;*/
  left: 0;
  width: 600px;
  height: 200px;
}
