* {
  -webkit-user-select: none;  /* Chrome, Safari, Opera */
  -moz-user-select: none;     /* Firefox */
  -ms-user-select: none;      /* IE/Edge */
  user-select: none;          /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*height: 100vh;*/
  margin: 0;
  background-color: #fff;
  background-image: url("./assets/back_pattern_2.svg");
  background-repeat: repeat;
}

#container {
  display: flex;
  flex-direction: column;
  /*min-height: 100vh;*/
  margin: 0 auto;
  height: calc(100vh - var(--header-height) - var(--footer-height));
  justify-content: space-between;
}

#header {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--header-height);
}

#main {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - var(--header-height) - var(--footer-height));
}

#matrix-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#matrix {
  width: calc(min(100vh - var(--header-height) - var(--footer-height), 100vw));
  min-width: calc(min(100vh - var(--header-height) - var(--footer-height), 100vw));
  max-width: calc(min(100vh - var(--header-height) - var(--footer-height), 100vw));

  height: calc(min(100vh - var(--header-height) - var(--footer-height), 100vw));
  min-height: calc(min(100vh - var(--header-height) - var(--footer-height), 100vw));
  max-height: calc(min(100vh - var(--header-height) - var(--footer-height), 100vw));
  
  background-color: #fff;
}

#footer {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space;
  height: var(--footer-height);
}

.header-content, .footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(min(100vh - 240px, 100vw));
  max-width: calc(min(100vh - 240px, 100vw));
  min-width: calc(min(100vh - 240px, 100vw));
  margin: auto;
}

.svg_icons {
  object-fit: contain; object-position: center;
}

.cilinder {
  /*width: 300px;*/
  max-width: 500px;
  max-height: 500px;  
}

#title {
  text-align: center;
}

.spacer {
  width: 120px;
}

#middle-circle {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
